<template>
  <div class="pos-list-wrap">
    <div class="button-wrap">
      <el-button
        v-if="!$route.path.includes('search')"
        type="primary"
        style="width: 100%"
        @click="openDialog('searchShopPos')"
      >
        條件搜尋
      </el-button>
    </div>
    <el-table
      ref="listTable"
      :data="renderList"
      height="100%"
      style="width: 100%"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      cell-class-name="pos-cell"
      :span-method="arraySpanMethod"
      empty-text="尚無資料"
    >
      <el-table-column prop="shop_name" label="門市">
        <template #default="scope">
          <p style="line-height: 40px">{{ scope.row.shop_erp_id }} {{ scope.row.shop_name }}</p>
        </template>
      </el-table-column>
      <el-table-column label="線上訂購" width="160">
        <template #default="scope">
          <p class="online-order-btn" v-if="scope.row.accept_online_order == true">
            <el-tag type="success">開啟</el-tag>
          </p>
          <p class="online-order-btn" v-else>
            <el-tag type="danger">關閉</el-tag>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="機台" width="160" />
      <el-table-column prop="connection_status" label="連線狀態" width="160">
        <template #default="scope">
          <div v-for="(item, index) in scope.row.client_array" :key="index" class="pos-item">
            <p>{{ 'POS - ' + item.client_id }}</p>
            <p>
              <el-tag v-if="item.connection_status === 1" type="success">連線中</el-tag>
              <el-tag v-else-if="item.connection_status === -1" type="danger">未連線</el-tag>
            </p>
            <p>{{ formatDateTime('YYYY/MM/DD HH:mm', item.last_connection_datetime) }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="最後連線時間" width="160" />
      <el-table-column fixed="right" align="right" width="200">
        <template #default="scope">
          <el-button
            type="info"
            plain
            style="font-weight: normal; border: none; width: 110px; margin-top: 8px"
            @click="handleClickDetail(scope.$index, scope.row)"
          >
            詳細
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      :page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="getPageTotal"
      @current-change="handleCurrentChange"
    />
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { ref, watch, computed, defineAsyncComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { formatDateTime } from '@/utils/datetime';
import { Search } from '@element-plus/icons-vue';
import { useDialog } from '@/utils/dialog';
export default {
  name: 'shop-pos-list',
  components: {
    ShopPosDialog: defineAsyncComponent(() => import('@/components/shopSystem/ShopPosDialog.vue')),
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    //合併儲存格
    const arraySpanMethod = ({ columnIndex }) => {
      if (columnIndex === 2) {
        return [0, 0];
      } else if (columnIndex === 3) {
        return [1, 3];
      } else if (columnIndex === 4) {
        return [0, 0];
      }
    };
    //取得資料
    const { getShopPosList, getPageSize, getPageTotal, getIsDetail } = useGetters('shopSystem', [
      'getShopPosList',
      'getPageSize',
      'getPageTotal',
      'getIsDetail',
    ]);
    const doGetShopPosList = useActions('shopSystem', ['doGetShopPosList']);
    const SET_IS_DETAIL = useMutations('shopSystem', ['SET_IS_DETAIL']);
    //初始化
    const listTable = ref(null);
    const currentPage = ref(1);
    const query = { page: route.query.page ? route.query.page : 1 };
    router.push({ query, replace: true }).then(async () => {
      currentPage.value = Number(route.query.page);
      if (route.path === '/system/shop' && !getIsDetail.value) {
        await doGetShopPosList();
      } else {
        SET_IS_DETAIL(false);
      }
    });
    const renderList = computed(() =>
      getShopPosList.value.slice((currentPage.value - 1) * getPageSize.value, currentPage.value * getPageSize.value)
    );
    //點擊分頁
    const getSearchCondition = useGetters('shopSystem', ['getSearchCondition']);
    const doSearchShopPosList = useActions('shopSystem', ['doSearchShopPosList']);
    const handleCurrentChange = async () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };
    watch(
      () => route.query.page,
      async () => {
        if (route.query.page) {
          currentPage.value = Number(route.query.page);
          if (!getSearchCondition.value) {
            await doGetShopPosList();
          } else {
            await doSearchShopPosList(getSearchCondition.value);
          }
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );
    //修正再次搜尋時currentPage值
    watch(
      getSearchCondition,
      () => {
        currentPage.value = 1;
      },
      { deep: true }
    );

    //點擊詳細
    const doGetShopConfigDetail = useActions('shopSystem', ['doGetShopConfigDetail']);
    const handleClickDetail = async (index, item) => {
      await doGetShopConfigDetail(item);
      router.push(route.path + '/detail');
    };

    //彈窗
    const { dialogTemplate, openDialog } = useDialog();
    return {
      Search,
      formatDateTime,
      listTable,
      currentPage,
      arraySpanMethod,
      getPageSize,
      getPageTotal,
      renderList,
      handleCurrentChange,
      handleClickDetail,
      dialogTemplate,
      openDialog,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$pagination-height: 50px;
.pos-list-wrap {
  width: 100%;
  height: 100%;
  padding: $padding-unit $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.button-wrap {
  position: absolute;
  top: calc($padding-unit + $row-height / 2);
  right: 22px;
  z-index: 10;
  transform: translateY(-50%);
  width: 110px;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}

.pos-item {
  display: grid;
  grid-template-columns: repeat(3, 160px);
  height: 40px;
  p {
    line-height: 40px;
  }
}

.online-order-btn {
  line-height: 40px;
}
</style>
<style>
.pos-cell {
  vertical-align: top !important;
}
</style>
